<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <!-- 选择权属有内容页面 -->
    <div class="have">
        <!-- 渲染步骤条组件 -->
        <main-steps :active="1"></main-steps>
        <div class="have_main">
            <img :src="titleIcon" />
            <el-button type="primary" class="add" @click="goOwnership">新增</el-button>
            <!-- 权属弹窗 -->
            <pop-ownership class="haveone"> </pop-ownership>
            <div class="btns">
                <next-tick
                    :btn1="0"
                    :btn2="1"
                    :btn3="1"
                    :btn4="1"
                    class="tick"
                    @nextTick="nextTick('ruleForm')"
                ></next-tick>
            </div>
        </div>
    </div>
</template>

<script>
import MainSteps from '@/views/MainSteps.vue';
import PopOwnership from '@/components/ownership/PopOwnership.vue';
import NextTick from '@/components/NextTick.vue';

export default {
    name: 'hasownership',
    components: {
        'main-steps': MainSteps, // 步骤条组件
        'pop-ownership': PopOwnership, // 权属弹窗
        'next-tick': NextTick, // 上一步下一步按钮
    },
    data() {
        return {
            titleIcon: require('@/assets/img/title2.png'),
            pointIcon: require('@/assets/img/point2.png'),
        };
    },
    methods: {
        goOwnership() {
            let obj = {
                updata: true,
                cp_id: '',
            };
            sessionStorage.setItem('own', JSON.stringify(obj));
            if (sessionStorage.getItem('ownership')) {
                sessionStorage.removeItem('ownership');
            }
            this.$router.push({
                path: '/ownership',
            });
        },
    },
    mounted() {
        this.$store.commit('changeOwn', false);
    },
};
</script>

<style scoped>
.have {
    background: #ffffff;
    border-radius: 4px;
    width: 1200px;
    margin: auto;
}

.have_main {
    min-height: calc(100vh - 281px);
    width: 1200px;
    margin: auto;
    position: relative;
    background: #fff;
}
.have_main img {
    width: 11px;
    height: 11px;
    position: absolute;
    top: 32px;
    left: 170px;
}
.btns {
    position: relative;
}
.add {
    width: 80px;
    height: 40px;
    background: #2d63e0;
    border-radius: 4px;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 0;
    position: absolute;
    top: 19px;
    right: 170px;
}
</style>